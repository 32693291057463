import { OptionalAlternates } from '~/models/OptionalAlternates'
import { normalizeIndexPageSlug } from '~/utils/storyblok'
import { StoryblokConfig } from '~/config/storyblok'
import { removeTrailingSlash } from '~/utils/str'
import { FunctionComponent } from 'react'
import { Story } from '@storyblok/react'
import { Host } from 'config/host'
import Head from 'next/head'

interface Props {
  story: Story<OptionalAlternates>
}

function includeHostName(slug: string): string {
  return `${Host.name}/${slug}`
}

function prefixLocale(slug: string, locale: string): string {
  if (slug) {
    return `${locale}/${slug}`
  }

  return locale
}

export const Alternates: FunctionComponent<Props> = ({ story }) => {
  const alternateDefaultLocaleHref = removeTrailingSlash(
    includeHostName(
      prefixLocale(
        normalizeIndexPageSlug(story.default_full_slug), StoryblokConfig.defaultLocale
      )
    )
  )

  return (
    <Head>
      {/* Alternates for the default locale */}
      <link rel="alternate" hrefLang="sv" href={alternateDefaultLocaleHref} />

      {/* Alternates for the translated locale(s) */}
      {story.translated_slugs.map(({ lang, path }, index) => {
        const href = removeTrailingSlash(
          includeHostName(
            prefixLocale(
              normalizeIndexPageSlug(path), lang
            )
          )
        )

        return <link rel="alternate" hrefLang={lang} href={href} key={index} />
      })}
    </Head>
  )
}
