import { StoryblokConfig } from '~/config/storyblok'
import { removeTrailingSlash } from '~/utils/str'
import { FunctionComponent } from 'react'
import { Story } from '@storyblok/react'
import { Host } from 'config/host'
import Head from 'next/head'

interface Props {
  culture: string
  story: Story
}

function generateCanonicalHref(slug: string, culture: string): string {
  if (slug === StoryblokConfig.startPageSlug) {
    return removeTrailingSlash(`${Host.name}/${StoryblokConfig.defaultLocale}`)
  } else if (slug.includes(StoryblokConfig.startPageSlug)) {
    return removeTrailingSlash(`${Host.name}/${slug.replace(StoryblokConfig.startPageSlug, '')}`)
  }

  if (!`${Host.name}/${slug}`.includes(`${Host.name}/${culture}`)) {
    return removeTrailingSlash(`${Host.name}/${culture}/${slug}`)
  }

  return removeTrailingSlash(`${Host.name}/${slug}`)
}

export const Canonical: FunctionComponent<Props> = ({ story, culture }) => {
  return (
    <Head>
      <link rel="canonical" href={generateCanonicalHref(story.full_slug, culture)} />
    </Head>
  )
}
