let name = ''

if (process.env.NEXT_PUBLIC_SITE_URL) {
  name = process.env.NEXT_PUBLIC_SITE_URL
} else if (process.env.VERCEL_URL) {
  name = `https://${process.env.VERCEL_URL}`
} else {
  name = 'http://localhost:3000'
}

export const Host = {
  name,
}
