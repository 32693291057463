import { Story, ContentDeliveryLink, useStoryblokApi } from '@storyblok/react'
import { StoryblokConfig } from 'config/storyblok'

export type GetStaticPathsParam = {
  slug: string[]
}

export type GetStaticPropsProps = {
  story: Story
}

export async function getStory<T = any>(params: string[]): Promise<Story<T>> {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const storyblok = useStoryblokApi()

  const endpoint = `cdn/stories/${params.slice(1).join('/') || StoryblokConfig.startPageSlug}`

  const response = await storyblok.get(endpoint, {
    version: process.env.NODE_ENV === 'production' ? 'published' : 'draft',
    language: params[0]
  })

  return response.data.story as Story<T>
}

export async function getPaths(): Promise<{ params: GetStaticPathsParam }[]> {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const storyblok = useStoryblokApi()

  const response = await storyblok.get('cdn/links')
  const paths: { params: GetStaticPathsParam }[] = []

  Object.values(response.data.links as ContentDeliveryLink[])
    .filter(link => !link.is_folder)
    .forEach(link => {
      if (link.slug === StoryblokConfig.startPageSlug) {
        paths.push({ params: { slug: [StoryblokConfig.defaultLocale] } })
      } else {
        paths.push({ params: { slug: [StoryblokConfig.defaultLocale, ...link.slug.replace(/\/+$/, '').split('/')] } })
      }

      link.alternates.forEach(alternate => {
        if (alternate.path === StoryblokConfig.startPageSlug) {
          paths.push({ params: { slug: [alternate.lang] } })
        } else {
          paths.push({ params: { slug: [alternate.lang, ...alternate.path.replace(/\/+$/, '').split('/')] } })
        }
      })
    })

    return paths
}

export function normalizeIndexPageSlug(slug: string): string {
  return slug !== StoryblokConfig.startPageSlug ? slug : ''
}
