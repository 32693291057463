import { Story, useStoryblokState, StoryblokComponent } from '@storyblok/react'
import { GetStaticPathsParam, getStory, getPaths } from 'utils/storyblok'
import { GetStaticPaths, GetStaticProps, NextPage } from 'next'
import { Alternates } from '~/components/Alternates/Alternates'
import { GlobalSettingPage } from 'models/GlobalSettingPage'
import { Canonical } from '~/components/Canonical/Canonical'
import { GlobalContext } from 'context/GlobalContext'
import { StoryblokConfig } from 'config/storyblok'
import { PartnerPage } from 'models/PartnerPage'
import { useLocale } from '~/hooks/locale'
import { Page } from 'models/Page'

interface Props {
  globals: Story<GlobalSettingPage>
  story: Story<Page | PartnerPage>
}

const SlugPage: NextPage<Props> = ({ story: initialStory, globals }) => {
  const story = useStoryblokState(initialStory)
  const { culture } = useLocale()

  if (!story.content) {
    return null
  }

  return (
    <>
      <Alternates story={story} />
      <Canonical story={story} culture={culture} />

      <GlobalContext.Provider value={{
        cookieConcent: globals.content.cookieConsent && globals.content.cookieConsent[0],
        blogFooter: globals.content.blogFooter[0],
        navigation: globals.content.navigation[0],
        footer: globals.content.footer[0],
      }}>
        <StoryblokComponent blok={story.content} story={story} />
      </GlobalContext.Provider>
    </>
  )
}

export const getStaticProps: GetStaticProps<Props, GetStaticPathsParam> = async (ctx) => {
  try {
    return {
      props: {
        globals: await getStory([`${ctx.params?.slug[0]}`, 'settings']),
        story: await getStory(ctx.params?.slug || []),
      },
      revalidate: StoryblokConfig.revalidate,
    }
  } catch (e) {
    return {
      notFound: true
    }
  }
}

export const getStaticPaths: GetStaticPaths<GetStaticPathsParam> = async () => ({
  paths: await getPaths(),
  fallback: 'blocking',
})

export default SlugPage
